<template>
  <div>
    <img :src="imgUrl" width="100%" />
    <audio ref="audio" :src="audioSrc" style="display: none"></audio>
    <FloatIcons padding="10 10 40 10" class="icons-warp">
      <div class="float-icon-item">
        <!-- <img style="width:30px" src="../assets/img/logo4.png" alt=""/> -->
        <span
          class="iconfont icon-bofang"
          v-show="play"
          @click="startPlayOrPause"
        ></span>
        <span
          class="iconfont icon-ico_zanting_xuanzhong"
          v-show="!play"
          @click="startPlayOrPause"
        ></span>
      </div>
    </FloatIcons>
  </div>
</template>
<script>
import FloatIcons from "../components/FloatIcons";
import { getDaily } from "../api/daily";

export default {
  components: { FloatIcons },
  data() {
    return {
      imgUrl: null,
      user: null,
      play: true,
      audioSrc: "",

      query: {},
    };
  },
  created() {
    this.query = this.$route.query;
    let key = this.query.key;
    if (key == null || key == undefined) {
      return;
    }

    // checkKey(key).then((res) => {
    //   if (res==null || res.data == "false") {
    //     return;
    //   }
    // });

    getDaily(this.query).then((res) => {
      if (res == null || res.data == null) {
        return;
      } else {
          this.imgUrl = res.data.image;
      }
    });
  },
  methods: {
    startPlayOrPause() {
      if (this.play) {
        this.$refs.audio.play();
      } else {
        this.$refs.audio.pause();
      }
      this.play = !this.play;
    },
  },
};
</script>
<style scoped>
.iconfont {
  font-size: 30px;
  color: #fff;
}
.float-icon-item {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.icons-warp {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
